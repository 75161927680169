import React, { Component } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <div>
                <section className="bg-white py-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4 d-f-center align-items-center">
                                <a href="https://play.google.com/store/apps/details?id=com.hungrytop" target="_blank" className="w-25">
                                    <img src="../assets/images/social-img/google-play.png" className="img-fluid" alt="" />
                                </a>
                            </div>
                            <div className="col-sm-4">
                                <div className="text-center">
                                    <p className="font-weight-bolder font-25">www.hungrytop.com</p>
                                    <p>Registered Office :</p>
                                    <p>HungryTop Technologies Limited<br />BL-5, PH-II, Golf Green Urban Complex,<br />Kolkata- 700095</p>
                                    <p>Tel: +91 9836809855 |  Email: info@hungrytop.com</p>
                                    <Link to="/terms-and-conditions">Terms conditions</Link>
                                    <Link to="/help" className="mx-2">Help</Link>
                                    <Link to="/privacy-policy" className="mx-2">Privacy policy</Link><br />
                                    <ExpandLessIcon />
                                    <p className="f-sans-serif">Back to Top</p>
                                </div>
                            </div>
                            <div className="col-sm-4 d-f-center align-items-center">
                                <div className="font-15 text-center">
                                    <img src="https://static.wixstatic.com/media/39f8d9_f8e34df8f67649ac86e9a7ae2a4068b8~mv2.jpg/v1/fill/w_118,h_38,al_c,q_80,usm_0.66_1.00_0.01/follow%2520us%2520images_edited.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="footer">All Rights Reserved ©️ 2021-26 | Hungry Top Technologies Limited®️</div>
            </div>
        );
    }
}

export default Footer;