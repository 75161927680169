import './App.css';
import HomePage from './homePage';
import { Switch, Route, Redirect } from 'react-router-dom';
import TeamPage from './teampage';
import Auction from './auction';
import auctioneer from './auctioneer';
import Bulletin from './bulletin';
import TermConditions from './term-conditions';
import Privacy from './privacy';
import HelpPage from './helpPage';
import Sellertnc from './sellertnc';
import OutletReports from './outlet-reports';

function App() {
  return (
    <div className="App">
      <div>
        <Switch>
          <Route activeClassName='is-active' path="/" component={HomePage} exact={true} />
          <Route activeClassName='is-active' path="/team" component={TeamPage} exact={true} />
          <Route activeClassName='is-active' path="/auction" component={Auction} exact={true} />
          <Route activeClassName='is-active' path="/auctioneer" component={auctioneer} exact={true} />
          <Route activeClassName='is-active' path="/bulletin" component={Bulletin} exact={true} />
          <Route activeClassName='is-active' path="/terms-and-conditions" component={TermConditions} exact={true} />
          <Route activeClassName='is-active' path="/privacy-policy" component={Privacy} exact={true} />
          <Route activeClassName='is-active' path="/help" component={HelpPage} exact={true} />
          <Route activeClassName='is-active' path="/sellertnc" component={Sellertnc} exact={true} />
          <Route activeClassName='is-active' path="/outlet/:outletId/:token" component={OutletReports} exact={true} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default App;
