import React, { Component } from 'react';
import Footer from './component/footer';
import Navber from './component/navber';
import { Link } from 'react-router-dom';

class TeamPage extends Component {
    render() {
        return (
            <>
                <Navber />
                <section className="bg-gray py-5">
                    <h1 className="team-header">Meet The Team</h1>
                    <div className="container">
                        <div className="team-container">
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/dev-datta.webp" alt="" />
                                <div className="mt-3">
                                    <span className="team-name">Dev Datta</span>
                                    <p className="team-para">Co - Founder & Chief Executive Officer - Interim</p>
                                    <a href="https://www.linkedin.com/in/dev-datta-38637017" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                            {/* <div className="team">
                                <img className="team-img" src="../assets/images/team/anirban.webp" alt="" />
                                <div className="mt-3">
                                    <span className="team-name">Anirban Chakraborty</span>
                                    <p className="team-para">Co - Founder, Director & Chief Marketing Officer</p>
                                    <a href="https://www.linkedin.com/in/anirban-chakraborty-55a33493/" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div> */}
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/jaydip.webp" alt="" />
                                <div className="mt-3">
                                    <span className="team-name">Joydip Lahiri</span>
                                    <p className="team-para">Independent Director & Blockchain Architect (Food Safety)</p>
                                    <a href="https://www.linkedin.com/in/joydip-lahiri-a7112117" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/babulal.webp" alt="Babulal Nolkha" />
                                <div className="mt-3">
                                    <span className="team-name">Babulal Nolkha</span>
                                    <p className="team-para">Chartered Account & Compliance Officer</p>
                                    <a href="https://www.linkedin.com/in/babulal-nolkha-78450b105" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="team-container">
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/amitava.webp" alt="Amitava" />
                                <div className="mt-3">
                                    <span className="team-name">Amitava Roy Chowdhoury</span>
                                    <p className="team-para">Director & Chief Technology Officer</p>
                                    <a href="https://www.linkedin.com/in/amitava-roy-chowdhoury-4306a432" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/sougata.webp"  alt="Sougata Majumder" />
                                <div className="mt-3">
                                    <span className="team-name">Sougata Majumder</span>
                                    <p className="team-para">Product Experience & Design - Mentor</p>
                                    <a href="https://www.linkedin.com/in/sougatam" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/moumita.webp" alt="Moumita Biswas​​" />
                                <div className="mt-3">
                                    <span className="team-name">Moumita Biswas​​</span>
                                    <p className="team-para">Director & Human Resource Head</p>
                                    <a href="#">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="team-container">
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/rajnikant.webp" alt="Rajnikant Patel" />
                                <div className="mt-3">
                                    <span className="team-name">Rajnikant Patel</span>
                                    <p className="team-para">Mentor</p>
                                    <a href="https://www.linkedin.com/in/rajnickantpatel" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/gurunath.webp" alt="Gurunath Mudlapur" />
                                <div className="mt-3">
                                    <span className="team-name">Gurunath Mudlapur</span>
                                    <p className="team-para">Mentor</p>
                                    <a href="https://www.linkedin.com/in/gurunath-mudlapur-6a670a" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/eman_kalyan.webp" alt="Eman Kalyan Ghosh" />
                                <div className="mt-3">
                                    <span className="team-name">Eman Kalyan Ghosh</span>
                                    <p className="team-para">Founder & Collaborator</p>
                                    <a href="https://www.linkedin.com/in/eman-kalyan-ghosh-564a2618" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="team-container">
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/asutosh.webp" alt="Ashutosh Mishra" />
                                <div className="mt-3">
                                    <span className="team-name">Ashutosh Mishra</span>
                                    <p className="team-para">Business Strategy Head</p>
                                    <a href="https://www.linkedin.com/in/ashutosh-mishra-3240b68" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/devendra.webp" alt="Devendra Tripathi" />
                                <div className="mt-3">
                                    <span className="team-name">Devendra Tripathi</span>
                                    <p className="team-para">Mentor</p>
                                    <a href="https://www.linkedin.com/in/devendra-tripathi-46617b" target="blank">
                                        <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="team">
                                <img className="team-img" src="../assets/images/team/indranil.webp" alt="Dr. Indranil Nandi" />
                                <div className="mt-3">
                                    <span className="team-name">Dr. Indranil Nandi</span>
                                    <p className="team-para">Mentor</p>
                                    <a href="https://www.linkedin.com/in/indranilnandi" target="blank">
                                    <img src="../assets/images/linkedIn.webp" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
                <hr />
                <Footer />
            </>
        );
    }
}

export default TeamPage;