import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

class HelpPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qesNo: 1
        }
    }
    handleDropAns = (num) => {

        if (this.state.qesNo === num) {
            this.setState({
                qesNo: 0
            })
        } else {
            this.setState({
                qesNo: num
            })
        }
    }
    render() {
        return (
            <div className='container pb-5'>
                <div className="help-sec">
                    <div className='d-flex justify-content-center'>
                        <img src="../assets/images/hungry-logo.svg" alt='' className='help-logo' />
                    </div>
                    <div className='help-header'>
                        <h2 className='help-title'>Terms & conditions of Food Auction</h2>
                        <p className='help-para'>
                            About FOOD auction: Hungry Top is a well-diversified Food & Beverage Auction Platform that offers an unique food auction concept where customers can win food placing their highest bid during the game play.
                        </p>
                    </div>
                    <div className='q-ans'>
                        <h3 className='q-title'>Questions And Answers :</h3>
                        <div>
                            <div class="card mb-2">
                                <div class="card-header" onClick={(e) => this.handleDropAns(1)}>
                                    <h5 class="mb-0">
                                        Q : How To Participate in the Food Auction?
                                    </h5>
                                </div>

                                <div style={this.state.qesNo === 1 ? { display: "block" } : { display: "none" }}>
                                    <div class="card-body">
                                        Welcome To the Most Thrilling food bidding experience only at Hungry Top. Hungry Top is the World's First Food Auction Platform.
                                        To participate at the food auction, please Subscribe from the Profile Section of the Hungry Top App.
                                        Choose the Subscription Plan that suits you the best.
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-2">
                                <div class="card-header" onClick={(e) => this.handleDropAns(2)}>
                                    <h5 class="mb-0">
                                        Q : Way Of Engagement At The Food Auction?
                                    </h5>
                                </div>

                                <div style={this.state.qesNo === 2 ? { display: "block" } : { display: "none" }}>
                                    <div class="card-body">
                                        After you have taken subscription plan follow the steps below. Click on the Auction icon.
                                        Participate for your favorite food/restaurant available.
                                        Bid the amount you want to enter.
                                        And Win your food being the Top Bidder.
                                        Once you win an auction in a day, you cannot participate on the same day again at the auction again..
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-2">
                                <div class="card-header" onClick={(e) => this.handleDropAns(3)}>
                                    <h5 class="mb-0">
                                        Q : What are the rules and norms of the Food Auction?
                                    </h5>
                                </div>

                                <div style={this.state.qesNo === 3 ? { display: "block" } : { display: "none" }}>
                                    <div class="card-body">
                                        Once you win the auction either you order or not you won’t get a chance to play again at the auction on the same day
                                        Winners will get the food added to their cart respective cart section where they can place the order at their own bid price.
                                        All participants except the winners will get a special discount coupon through notifications after the auction is over, where they can avail a deep discount on the same food item that they bid for and enjoy their food.
                                        Kind attention to all auction winners: the auction items(food) is a promotional item from restaurant side. We request you all to order it within the stipulated time given. We have introduced the timer for that reason only.
                                        For all takeaway orders, please try to collect it within an hour of placing the order. Otherwise, it is at the discretion of the merchant to reject/accept latecomers. Auction orders are non-refundable once customer placed their order.
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-2">
                                <div class="card-header" onClick={(e) => this.handleDropAns(4)}>
                                    <h5 class="mb-0">
                                        Q : What are taxes and charges applicable on food orders? 
                                    </h5>
                                </div>

                                <div style={this.state.qesNo === 4 ? { display: "block" } : { display: "none" }}>
                                    <div class="card-body">
                                    GST will be applicable across all food orders placed at Hungry Top Platform as per Government norms . Delivery Charges, Packaging Charges are applicable as per restaurant discretion.
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-2">
                                <div class="card-header" onClick={(e) => this.handleDropAns(5)}>
                                    <h5 class="mb-0">
                                        Q : How to Contact Us
                                    </h5>
                                </div>

                                <div style={this.state.qesNo === 5 ? { display: "block" } : { display: "none" }}>
                                    <div class="card-body">
                                    Write us on this Email: <em>support@hungrytop.in</em>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='help-footer'>
                        <h5>Need more help?</h5>
                        <button className='btn-contact mr-2'>
                            <div className='d-flex'>
                                <span className='mr-4'><FontAwesomeIcon icon={faPhone} color="#0075ff" /></span>
                                <div className='text-left'>
                                    <a href='#'>
                                        <p className='mb-0'>Contact Us</p>
                                        <p className='mb-0'>6289096774</p>
                                    </a>
                                </div>
                            </div>
                        </button>
                        <button className='btn-mail'>
                            <div className='d-flex'>
                                <span className='mr-4'><FontAwesomeIcon icon={faEnvelope} color="#c99521" /></span>
                                <div className='text-left'>
                                    <a href='#'>
                                        <p className='mb-0'>mail Us</p>
                                        <p className='mb-0'> support@hungrytop.in</p>
                                    </a>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default HelpPage;