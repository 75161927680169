import React from 'react';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

function CardOutletReport(props) {
    return (
        <div className="box-total">
            <p>{props.title}</p>
            <div className="total-manu">
                <div>
                    <p className='m-0'>
                        <span className="left-icon"><TrendingUpIcon style={{ color: props.rightIconColor }} /></span>
                        <span className='total-cout' style={{ color: props.rightIconColor }}>₹{Number(props.amount).toFixed(1)}
                        </span>
                        {(props.subCount || props.subCount === 0) &&
                            <sub className='text-color-primary'>({props.subCount})</sub>
                        }
                    </p>
                </div>
                <div className="right-bar">
                    <div style={{ backgroundColor: props.rightIconColor }} className="bar bar-1"></div>
                    <div style={{ backgroundColor: props.rightIconColor }} className="bar bar-2"></div>
                    <div style={{ backgroundColor: props.rightIconColor }} className="bar bar-3"></div>
                    <div style={{ backgroundColor: props.rightIconColor }} className="bar bar-4"></div>
                    <div style={{ backgroundColor: props.rightIconColor }} className="bar bar-5"></div>
                    <div style={{ backgroundColor: props.rightIconColor }} className="bar bar-6"></div>
                    <div style={{ backgroundColor: props.rightIconColor }} className="bar bar-7"></div>
                </div>
            </div>
        </div>
    )
}

export default CardOutletReport;