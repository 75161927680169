import React, { Component } from 'react';
import { Parallax } from "react-parallax";
import Footer from './component/footer';
import Navber from './component/navber';

const img1 = "../assets/images/food10.jpg";
class Auction extends Component {
    render() {
        return (
            <>
                <Navber />
                <Parallax bgImage={img1}>
                    <div style={{ height: 500 }}>
                    </div>
                </Parallax>
                <div className="container p-5">
                    <h2 className="auction-header">Benvenuto, to our Food Auction Platform.</h2>
                    <div className="text-secondary">
                        <p>Everything we do, we believe in challenging the set food and beverage industry norms and status quo, reforming and reshaping market dynamics.</p>
                        <p>The way we challenge the status quo is by making our product uniquely designed, simple to use, and user friendly. We just happen to make your food craving a reality at your price through Auction.</p>
                        <p>HungryTop is a well-diversified Food & Beverage Auction Platform that offers a paramount range of food products from eminent chefs across the Globe.</p>
                    </div>
                    <h3 className="text-center text-secondary font-weight-bold mt-5">Would you like to Bid?</h3>
                </div>
                <hr/>
                <Footer/>
            </>
        );
    }
}

export default Auction;