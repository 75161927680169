import React, { Component } from 'react';
import { Parallax } from 'react-parallax';
import Navber from './component/navber';
import Footer from './component/footer';
const img1 = "../assets/images/Microphones.webp"
const img2 = "../assets/images/team/Rajesh_Sarma.webp"
class Bulletin extends Component {
    render() {
        return (
            <div>
                <Navber />
                <Parallax bgImage={img1}>
                    <div style={{ height: 500 }}>

                    </div>
                </Parallax>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-sm-3 d-flex justify-content-center align-items-center">
                            <img className="bulletin-img" src={img2} alt="Rajesh" />
                        </div>
                        <div className="col-sm-9 text-right p-3">
                            <h4 className="head-content font-weight-bold">Live Food Auction Events with Rajesh Sharma.<br />We will announce soon the chedule.​</h4>
                        </div>
                    </div>
                </div>
                <hr/>
                <Footer/>
            </div>
        );
    }
}

export default Bulletin;