import axios from 'axios';
const ax = (token) => axios.create({
    // baseURL : "https://demo.storeleak.com/api",
    baseURL: "https://api.hungrytop.in/api",
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'x-user-type': 'admin',
        'Authorization': token
    }
});

export default ax;