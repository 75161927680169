import React, { Component } from 'react';

class FoodAuction extends Component {
    render() {
        return (
            <div>
                <section className="food-auction-sec">
                    <div className="container-fluid">
                        <div className="row pt-5">
                            <div className="col-sm-6">
                                <div>
                                    <video className="food-auction-video" loop autoPlay muted playsInline>
                                        <source src="../assets/video/food2.webm" type="video/webm"></source>
                                        <source src="../assets/video/food2.mp4" type="video/mp4"></source>
                                    </video>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div>
                                    <h1>Food Auction </h1>
                                    <p className="font-25">India's Most Acclaimed Restaurants</p>
                                </div><div>
                                    <div className="underline-black mb-2"></div>
                                    <div className="underline-black"></div>
                                    <div className="mt-4">
                                        <p className="font-15 text-secondary">
                                            Everything we do, we believe in challenging the set food and beverage industry norms and status quo, reforming and reshaping market dynamics.
                                        </p>
                                        <p className="font-15 text-secondary">
                                            The way we challenge the status quo is by making our product uniquely designed, simple to use, and user friendly. We just happen to make your food craving a reality at your price through Auction.
                                        </p>
                                        <p className="font-15 text-secondary">
                                            HungryTop is a well-diversified Food & Beverage Auction Platform that offers a paramount range of food products from eminent chefs across the Globe.
                                        </p>
                                    </div>
                                    <h3 className="text-center text-secondary font-weight-bold mt-5">Would you like to Bid?</h3>
                                    <div className="d-f-center mt-5">
                                        <img src="https://static.wixstatic.com/media/39f8d9_1992534726a4401b8a6e8249a2474907~mv2.jpg/v1/fill/w_110,h_36,al_c,q_80,usm_0.66_1.00_0.01/iphone-google-play-android-png-favpng-pk.webp" 
                                            alt="png"
                                        />
                                        <span className="mx-3"></span>
                                        <img src="https://static.wixstatic.com/media/39f8d9_10196c120cb34cefb840d9c8d74f2148~mv2.png/v1/crop/x_1,y_0,w_899,h_312/fill/w_106,h_36,al_c,q_85,usm_0.66_1.00_0.01/png-clipart-app-store-logo-iphone-app-st.webp" 
                                            alt="png"
                                        />
                                    </div>
                                    <p className="text-center my-3 text-secondary">Tel: +91 9836809855  |  Email: info@hungrytop.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default FoodAuction;