import React, { Component } from 'react';
import FoodAuction from './component/food-auction';
import FromTable from './component/from-table';
import Footer from './component/footer';
import Navber from './component/navber';
import { Link, NavLink } from 'react-router-dom';
import { Parallax, Background } from "react-parallax";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ReplyIcon from '@material-ui/icons/Reply';
import TextField from '@material-ui/core/TextField';
import { pomotUser } from './api/api-service';
const image2 = "../assets/images/food-auction.jpg";
const image3 = "../assets/images/food7.jpg";
const image4 = "../assets/images/food9.jpg";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalState: true,
            modalName: "",
            modalMobileNo: "",
            modalEmail: "",
            isSubmitted: false,
            errorMsgApi: "",
            sucssesMsgApi: "",
        }
        this.handleShow = this.handleShow.bind(this);
        this.myRef = React.createRef();
    }
    // scroll(ref) {
    //     ref.current.scrollIntoView({behavior: 'smooth'})
    //   }
    handleShow() {
        this.setState({ modalState: !this.state.modalState });
    }
    handleChangeName(e) {
        this.setState({
            modalName: e.target.value
        }, () => {
            return this.state.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeMobile(e) {
        this.setState({
            modalMobileNo: e.target.value
        }, () => {
            return this.state.isSubmitted ? this.valid() : false;
        });
    }
    handleChangeEmail(e) {
        this.setState({
            modalEmail: e.target.value
        }, () => {
            return this.state.isSubmitted ? this.valid() : false;
        });
    }
    valid() {
        let checked = true;
        if (this.state.modalName.length < 1) {
            checked = false;
        }
        if (this.state.modalMobileNo.length !== 10) {
            checked = false;
        }
        // if (this.state.modalEmail.length < 5) {
        //     checked = false;
        // }
        return checked
    }
    // Api call ----
    createPomotUser = async () => {
        const data = {
            "name": this.state.modalName,
            "mobile": this.state.modalMobileNo,
            "email": this.state.modalEmail
        }
        try {
            const newPomot = await pomotUser(data);
            this.setState({
                sucssesMsgApi: newPomot.data.message,
                modalName: "",
                modalMobileNo: "",
                modalEmail: "",
                errorMsgApi: "",
                isSubmitted: false
            })
        } catch (error) {
            this.setState({
                errorMsgApi: error.response.data.message,
                sucssesMsgApi: ""
            })
            console.log(error);
        }
    }
    submitted = () => {
        this.setState({ isSubmitted: true });
        if (this.valid()) {
            this.createPomotUser();
        }
    }
    render() {
        return (
            <div>
                <div>
                    <video id="myVideo" loop autoPlay muted playsInline>
                        <source src="../assets/video/food1-team.webm" type="video/webm"></source>
                        <source src="../assets/video/food1-team.mp4" type="video/mp4"></source>
                    </video>
                </div>
                {/* content---- */}
                <div className="video-container">
                    <Navber />
                    <section className="main-header" id="home" >
                        <div className="text-center text-white">
                            <div>
                                <h2 className="head-content">Benvenuto!</h2>
                                <h1 className="sub-content">GOURMET MEAL<br /> AT YOUR DINNER TABLE</h1>
                                <h2 className="head-content">World's First Food Auction Platform</h2>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <div className="underline m-2"></div>
                                    <div className="underline m-2"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="about" >
                        <FoodAuction />
                    </section>
                    <Parallax bgImage={image2} strength={200}>
                        <div id="menus">
                            <div className="food-ad">
                                <section className="w-100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="sub-food">
                                                    <img src="../assets/images/food5.webp" alt="" />
                                                    <div className="text-center mt-4">
                                                        <h1>Tasting</h1>
                                                        <h1>Menu</h1>
                                                        <div className="d-flex justify-content-center align-items-center mt-4">
                                                            <div>
                                                                <div className="underline-black m-2"></div>
                                                                <div className="underline-black m-2"></div>
                                                            </div>
                                                        </div>
                                                        <p className="text-secondary my-3">9 course meal that’s bold on <br />taste, light on calories</p>
                                                        <Link to="#">See Menu -</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="sub-food">
                                                    <img src="../assets/images/food4.webp" alt="" />
                                                    <div className="text-center mt-4">
                                                        <h1>Chef's</h1>
                                                        <h1>Choice</h1>
                                                        <div className="d-flex justify-content-center align-items-center mt-4">
                                                            <div>
                                                                <div className="underline-black m-2"></div>
                                                                <div className="underline-black m-2"></div>
                                                            </div>
                                                        </div>
                                                        <p className="text-secondary my-3">You choose your favorite foods,  <br />I’ll assemble a divine menu</p>
                                                        <Link to="#">See Menu -</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="sub-food">
                                                    <img src="../assets/images/food3.webp" alt="" />
                                                    <div className="text-center mt-4">
                                                        <h1>Auction </h1>
                                                        <h1>We Trust</h1>
                                                        <div className="d-flex justify-content-center align-items-center mt-4">
                                                            <div>
                                                                <div className="underline-black m-2"></div>
                                                                <div className="underline-black m-2"></div>
                                                            </div>
                                                        </div>
                                                        <p className="text-secondary my-3">A variety of exotic wines paired <br /> with 7 fresh tapas</p>
                                                        <Link to="#">See Menu -</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </Parallax>
                    <FromTable />
                    {/* <section className="amazing-bg py-5"></section> */}
                    <Parallax bgImage={image3} strength={100}>
                        <div style={{ height: 700 }}>
                            <section className="food-ad" id="review" >
                                <div className="text-center">
                                    <span className="quotes">“</span>
                                    <p className="quotes-para">Absolutely loved it.<br />Amazing food and charismatic chefs.</p>
                                    <div className="d-f-center">
                                        <div className="quotes-sub-img">
                                            <img className="q-img" src="../assets/images/hungrytop.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Parallax>
                    <Parallax bgImage={image4} strength={100}>
                        <div style={{ height: 300 }}>
                            <section className="food-ad">
                                <h1 className="display-header text-white">your food! your price! your way!</h1>
                            </section>
                        </div>
                    </Parallax>
                    <section id="service" className="services-container bg-white">
                        <div>
                            <div className="p-5">
                                <h1 className="text-center display-4">At Your Service</h1>
                                <p className="text-center my-3 text-secondary f-sans-serif">Tel: +91 9836809855  |  Email: info@hungrytop.com</p>
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <div>
                                        <div className="underline-black m-2"></div>
                                        <div className="underline-black m-2"></div>
                                    </div>
                                </div>
                                <h3 className="font-weight-bold text-secondary f-sans-serif">Tell me a little about your taste so<br /> we can begin the creation process</h3>
                            </div>
                            <form className="f-sans-serif text-left" action="/action_page.php">
                                <div className="form-group">
                                    <label htmlFor="name" className="text-secondary font-15">Name*:</label>
                                    <input type="text" className="form-control" id="name" name="name" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="text-secondary font-15">Email*</label>
                                    <input type="text" className="form-control" id="email" name="email" />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="message" className="text-secondary font-15">Message*</label>
                                    <textarea className="form-control" id="message" name="message" rows="4"></textarea>
                                </div>
                                <div>
                                    <button type="submit" className="service-from-btn"><Link className="font-15" to="#">Submit</Link></button>
                                </div>
                            </form>
                        </div>
                    </section>
                    <section className="bg-white" id="photos">
                        <div className="img-hover-sec">
                            <div className="details-image">
                                <img src="../assets/images/momo2.jpg" alt="Avatar" className="image w-100" />
                                <div className="middle">
                                    <div className="text">
                                        <div className="pt-4">
                                            <h3>Food, Health, People.</h3>
                                            <p>The food that enters the mind must be watched as closely as the food that enters the body.</p>
                                        </div>
                                    </div>
                                    <div className="hover-icons">
                                        <FavoriteBorderIcon />
                                        <ReplyIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="details-image">
                                <img src="../assets/images/maggi.jpg" alt="Avatar" className="image w-100" />
                                <div className="middle">
                                    <div className="text">
                                        <div className="pt-4">
                                            <h3>Food, Soul, Body.</h3>
                                            <p>Food for the body is not enough, There must be food for the soul.</p>
                                        </div>
                                    </div>
                                    <div className="hover-icons">
                                        <FavoriteBorderIcon />
                                        <ReplyIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="details-image">
                                <img src="../assets/images/matthias.jpg" alt="Avatar" className="image w-100" />
                                <div className="middle">
                                    <div className="text">
                                        <div className="pt-4">
                                            <h3>Count memories, not calories.</h3>
                                            <p>Everything tastes good when you're hungry.</p>
                                        </div>
                                    </div>
                                    <div className="hover-icons">
                                        <FavoriteBorderIcon />
                                        <ReplyIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="details-image">
                                <img src="../assets/images/jo-sonn.jpg" alt="Avatar" className="image w-100" />
                                <div className="middle">
                                    <div className="text">
                                        <div className="pt-4">
                                            <h3>Food, Family & Friends.</h3>
                                            <p>When you eat food with your family and friends, it always tastes better!</p>
                                        </div>
                                    </div>
                                    <div className="hover-icons">
                                        <FavoriteBorderIcon />
                                        <ReplyIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                    {/* <button onClick={() => {this.scroll(this.myRef)}} className="footnote">Top</button> */}
                </div>
                {/* Modal ------ */}
                <div className={"modal fade" + (this.state.modalState ? " show d-block" : " d-none")} id="myModal">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div>
                                <div className="header-close-btn">
                                    <button className="close-btn" onClick={this.handleShow} data-dismiss="modal">&times;</button>
                                </div>
                                <div className="text-center">
                                    <h4 className="font-weight-bold">Get a Chance to Avail Early Access</h4>
                                    <p>Share your details and get a chance to be among the <br />first 100 to participate in this unique experience.</p>
                                </div>
                                <hr />
                            </div>
                            <div className="px-5">
                                <TextField
                                    onChange={(ev) => this.handleChangeName(ev)}
                                    className="w-100"
                                    value={this.state.modalName}
                                    label={<span>Name<span className="text-danger">*</span></span>} />
                                <p className="error-msg font-15">{this.state.modalName.length < 1 && this.state.isSubmitted ? "Please enter your name" : ""}</p>
                                <TextField
                                    onChange={(ev) => this.handleChangeMobile(ev)}
                                    className="w-100"
                                    value={this.state.modalMobileNo}
                                    type="number"
                                    label={<span>Mobile<span className="text-danger">*</span></span>} />
                                <p className="error-msg font-15">{this.state.modalMobileNo.length !== 10 && this.state.isSubmitted ? "Enter your mobile no" : ""}</p>
                                <TextField
                                    onChange={(ev) => this.handleChangeEmail(ev)}
                                    className="w-100 mb-2"
                                    value={this.state.modalEmail}
                                    label="Email ID" />
                                {this.state.errorMsgApi !== "" ? <div className="alert alert-danger">
                                    <strong>{this.state.errorMsgApi}</strong>
                                </div> : ""}
                                {this.state.sucssesMsgApi !== "" ? <div className="alert alert-success">
                                    <strong>{this.state.sucssesMsgApi}</strong>
                                </div> : ""}

                                <button className="modal-submit-btn" onClick={this.submitted}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="side-fix-menu">
                    <a href="/#home">
                        <li>HOME</li>
                        <label></label>
                    </a>
                    <a href="/#about">
                        <li>ABOUT</li>
                        <label></label>
                    </a>
                    <a href="/#menus">
                        <li>MENUS</li>
                        <label></label>
                    </a>
                    <a href="/#review">
                        <li>REVIEWS</li>
                        <label></label>
                    </a>
                    <a href="/#service">
                        <li>CONTACT</li>
                        <label></label>
                    </a>
                    <a href="/#photos">
                        <li>PHOTOS</li>
                        <label></label>
                    </a>
                </div>
            </div>
        );
    }
}

export default HomePage;