import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { useHistory } from "react-router";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import KitchenIcon from '@material-ui/icons/Kitchen';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import TrackChangesSharpIcon from '@material-ui/icons/TrackChangesSharp';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer() {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem onClick={() => history.push("/")}>
          <ListItemIcon> <HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem onClick={() => history.push("/team")}>
          <ListItemIcon> <GroupIcon /></ListItemIcon>
          <ListItemText primary="Team" />
        </ListItem>
        <ListItem onClick={() => history.push("/auction")}>
          <ListItemIcon> <FastfoodIcon /></ListItemIcon>
          <ListItemText primary="Food Auction" />
        </ListItem>
        <ListItem onClick={() => history.push("/auctioneer")}>
          <ListItemIcon> <KitchenIcon /></ListItemIcon>
          <ListItemText primary="Auctioneer" />
        </ListItem>
        <ListItem>
          <a href="/#service" style={{display: "contents", color: "black"}}>
            <ListItemIcon> <PhoneForwardedIcon /></ListItemIcon>
            <ListItemText primary="Contact" />
          </a>
        </ListItem>
        <ListItem onClick={() => history.push("/bulletin")}>
          <ListItemIcon> <TrackChangesSharpIcon /></ListItemIcon>
          <ListItemText primary="Bulletin" />
        </ListItem>
      </List>
      {/* <Divider /> */}
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <FormatListBulletedIcon onClick={toggleDrawer(anchor, true)} />
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}