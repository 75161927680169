import api from "./base-api";

const pomotUser = (bodyData) => {
    return api('token').post(`/user/create-pomot-user`, bodyData);
}
const getReportOutlet = (outletId, pageNo, type, token) => {
    return api(token).get(`/report/outlet/${outletId}/orders?currentPage=${pageNo}&type=${type}`);
}
const getReportOutletDaily = (outletId, date, pageNo, type, token) => {
    return api(token).get(`/report/outlet/${outletId}/orders/daily?month=${date}&currentPage=${pageNo}&type=${type}`);
}
const getReportOutletMonthly = (outletId, date, pageNo, type, token) => {
    return api(token).get(`/report/outlet/${outletId}/orders/monthly?month=${date}&currentPage=${pageNo}&type=${type}`);
}

export {
    pomotUser,
    getReportOutlet,
    getReportOutletDaily,
    getReportOutletMonthly
}