import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TermConditions extends Component {
    render() {
        return (
            <div style={{ backgroundColor: "#f3f3f3" }}>
                <div className="container bg-white p-lg-5">
                    <div className="text-center font-weight-bold">
                        <h1 className="text-center">Hungry Top - Terms of Use – June 2021</h1>
                        <hr className="w-75" />
                    </div>
                    <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of <Link to="https://www.hungrytop.com/">www.hungrytop.com</Link> website and Hungry Top application for mobile and handheld devices.</p>
                    <h4>Terms of Use</h4>
                    <ul>
                        <li><p>These terms of use (the "<b>Terms of Use</b>") govern your use of our website <Link to="https://www.hungrytop.com/">www.hungrytop.com</Link> (the "<b>Website</b>") and our "HungryTop" application for mobile and handheld devices (the "<b>App</b>"). The Website and the App are jointly referred to as the "<b>Platform</b>". Please read these Terms of Use carefully before you use the services. If you do not agree to these Terms of Use, you may not use the services on the Platform, and we request you to uninstall the App. By installing, downloading or even merely using the Platform, you shall be contracting with HungryTop and you signify your acceptance to this Terms of Use and other HungryTop policies (including but not limited to the Cancellation & Refund Policy, Privacy Policy and Take Down Policy) as posted on the Platform and amended from time to time, which takes effect on the date on which you download, install or use the Platform, and create a legally binding arrangement to abide by the same.</p></li>
                        <li><p>The Platform is owned and operated by Hungry Top Technologies Limited, a limited company incorporated under the Companies Act, 1956 and having its registered office at BL-5, PH-II, Golf Green Urban Complex, Kolkata- 7000095, West Bengal, India. For the purpose of these Terms of Use, wherever the context so requires, "you", “user”, or “User” shall mean any natural or legal person who shall transaction on the Platform by providing registration data while registering on the Platform as a registered user using any computer systems. The terms "HungryTop", "we", "us" or "our" shall mean Hungry Top Technologies Limited.</p></li>
                        <li><p>HungryTop enables transactions on its Platform between participating restaurants/merchants and buyers, dealing in (a) prepared food and beverages, (b) consumer goods, and (c) other products and services ("<b>Platform Services</b>"). The buyers ("<b>Buyer/s</b>") can choose and place orders ("<b>Orders</b>") from a variety of products and services listed and offered for sale by various merchants including but not limited to the restaurants, eateries and grocery stores ("<b>Merchant/s</b>"), on the Platform. Further, the Buyer can also place Orders for undertaking certain tasks on the Platform (“<b>Tasks</b>”). </p></li>
                        <li><p>HungryTop enables delivery of such Orders or completion of Tasks at select localities of serviceable cities across India ("<b>Delivery Services</b>") by connecting third party service providers i.e. pick-up and delivery partners (“<b>PDP</b>”) who will be responsible for providing the pick-up and delivery services and completing Tasks initiated by the users of the Platform (Buyers or Merchants). The Platform Services and Delivery Services are collectively referred to as "<b>Services</b>". For both Platform Services and Delivery Services, HungryTop is merely acting as an intermediary between the Merchants and Buyers and/or PDPs and Buyers/Merchants.</p>
                            <p>PDPs are individual entrepreneurs engaged with HungryTop on a voluntary, non-exclusive and principal to principal basis to provide aforementioned services for service fee. PDPs are independent contractors and are free to determine their timings of work.  HungryTop does not exercise control on the PDPs and the relationship between the PDPs and HungryTop is not that of an agent and principal or employee and employer. </p></li>
                        <li><p>For the pickup and delivery services and completing the Tasks, PDPs may charge the users of the Platform (Buyers or Merchants), a service fee (inclusive of applicable taxes whenever not expressly mentioned) determined on the basis of various factors including but not limited to distance covered, time taken, demand for delivery services/Tasks, real time analysis of traffic and weather conditions, seasonal peaks or such other parameters as may be determined from time to time.</p></li>
                        <li>
                            <p><b>Amendments</b></p>
                            <p>These Terms of Use are subject to modifications. We reserve the right to modify or change these Terms of Use and other HungryTop policies at any time by posting modified documents on the Platform and notifying you of the same for your perusal. You shall be liable to update yourself of such changes, if any, by accessing the same. You shall, at all times, be responsible for regularly reviewing the Terms of Use and the other HungryTop policies and note the changes made on the Platform. Your continued usage of the Services after any change is posted constitutes your acceptance of the amended Terms of Use and other HungryTop policies. As long as you comply with these Terms of Use, HungryTop grants you a personal, non-exclusive, non-transferable, limited privilege to access, enter, and use the Platform. By accepting these Terms of Use, you also accept and agree to be bound by the other terms and conditions and HungryTop policies (including but not limited to Cancellation & Refund Policy, Privacy Policy and Notice and Take Down Policy) as may be posted on the Platform from time to time.</p>
                        </li>
                        <li>
                            <p><b>Use of Platform and Services</b></p>
                        </li>
                        <ul>
                            <li><p>All commercial/contractual terms are offered by and agreed to between Buyers and Merchants alone with respect to products and services being offered by the Merchants. The commercial/contractual terms include without limitation price, applicable taxes, shipping costs, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. HungryTop does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and Merchants. HungryTop may, however, offer support services to Merchants in respect to Order fulfilment, mode of payment, payment collection, call centre support and other ancillary services, pursuant to independent contracts executed by HungryTop with the Merchants. The price of the product and services offered by the Merchant are determined by the Merchant itself and HungryTop has no role to play in such determination of price in any way whatsoever.</p></li>
                            <li>
                                <p>Upon acceptance of any Order or Task by the PDPs, the pickup and delivery services or Task completion services (as the case may be) undertaken by him/her, shall constitute a separate contract for services between Merchants/Buyers and PDPs. HungryTop shall not be responsible for the services provided by PDP to Merchants/Buyers through the Platform. HungryTop may, however, offer support services to PDPs in respect of Order fulfilment, payment collection, call centre support, and other ancillary services, pursuant to independent contracts executed by HungryTop with the PDPs.</p>
                            </li>
                            <li><p>HungryTop does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of the Merchants. You are advised to independently verify the bona fides of any particular Merchant that you choose to deal with on the Platform and use your best judgment on that behalf. All Merchant offers and third-party offers are subject to respective party terms and conditions. HungryTop takes no responsibility for such offers.</p></li>
                            <li><p>HungryTop neither make any representation or warranty as to specifics (such as quality, value, salability, etc.) of the products or services proposed to be sold or offered to be sold or purchased on the Platform nor does implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform. HungryTop accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</p></li>
                            <li><p>HungryTop does not make any representation or warranty with respect to any aspect of the services being provided by the PDPs through the Platform including but not limited to pick up and delivery services and Task completion services to the Merchants or Buyers as the case may be.</p></li>
                            <li><p>HungryTop is not responsible for any non-performance or breach of any contract entered into between Buyers and Merchants, and between Merchants/Buyers and PDP on the Platform. HungryTop cannot and does not guarantee that the concerned Buyers, Merchants and PDPs will perform any transaction concluded on the Platform. HungryTop is not responsible for unsatisfactory or non-performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</p></li>
                            <li><p>HungryTop is operating an online marketplace and assumes the role of facilitator, and does not at any point of time during any transaction between Buyer and Merchant and/or Buyer and PDP on the Platform come into or take possession of any of the products or services offered by Merchant or PDP. At no time shall HungryTop hold any right, title or interest over the products nor shall HungryTop have any obligations or liabilities in respect of such contract entered into between Buyer and Merchant and/or Buyer and PDP.</p></li>
                            <li><p>HungryTop is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Merchant and the Buyer. In case of complaints from the Buyer pertaining to efficacy, quality, or any other such issues, HungryTop shall notify the same to Merchant and shall also redirect the Buyer to the consumer call center of the Merchant. The Merchant shall be liable for redressing Buyer complaints. In the event you raise any complaint on any Merchant accessed using our Platform, we shall assist you to the best of our abilities by providing relevant information to you, such as details of the Merchant and the specific Order to which the complaint relates, to enable satisfactory resolution of the complaint.</p></li>
                            <li><p>Similar to the above, HungryTop is only providing a platform for communication with PDP and does not provide any pick-up and delivery services or Task completion services with respect to the Orders placed by Merchants/Buyers on the Platform as it is merely facilitating Delivery Services by connecting the Merchants/Buyers with the PDP through the Platform. In case of complaints by the Merchants/Buyers for deficiency or lapse in the delivery services or Task completion services provided by PDP, HungryTop shall notify the same to the PDP and also assist Merchants/Buyers to the best of its abilities to enable satisfactory resolution of the complaint. </p></li>
                            <li><p>Please note that there could be risks in dealing with underage persons or people acting under false pretence.</p></li>
                            <li><p>HungryTop - Use of the Website and Apps (Android and iOS)</p></li>
                        </ul>
                    </ul>
                    <p>You agree, undertake and confirm that your use of Platform shall be strictly governed by the following binding principles:</p>
                    <ul>
                        <li><p>You shall not host, display, upload, download, modify, publish, transmit, update or share any information which:</p></li>
                        <ul>
                            <li><p>belongs to another person and which you do not have any right to;</p></li>
                            <li><p>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, slanderous, criminally inciting or invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</p></li>
                            <li><p>is misleading or misrepresentative in any way;</p></li>
                            <li><p>is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</p></li>
                            <li><p>harasses or advocates harassment of another person;</p></li>
                            <li><p>involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</p></li>
                            <li><p>promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</p></li>
                            <li><p>infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;</p></li>
                            <li><p>promotes an illegal or unauthorized copy of another person's copyrighted work (see "copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</p></li>
                            <li><p>contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</p></li>
                            <li><p>provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</p></li>
                            <li><p>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</p></li>
                            <li><p>contains video, photographs, or images of another person (with a minor or an adult);</p></li>
                            <li><p>tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</p></li>
                            <li><p>engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of products related to the Platform. Throughout these Terms of Use, HungryTop's prior written consent means a communication coming from HungryTop's Legal Department, specifically in response to your request, and expressly addressing and allowing the activity or conduct for which you seek authorization;</p></li>
                            <li><p>solicits gambling or engages in any gambling activity which is or could be construed as being illegal;</p></li>
                            <li><p>interferes with another user's use and enjoyment of the Platform or any third party's user and enjoyment of similar services;</p></li>
                            <li><p>refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use;</p></li>
                            <li><p>harm minors in any way;</p></li>
                            <li><p>infringes any patent, trademark, copyright or other intellectual property rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</p></li>
                            <li><p>violates any law for the time being in force;</p></li>
                            <li><p>deceives or misleads the addressee/users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p></li>
                            <li><p>impersonate another person;</p></li>
                            <li><p>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</p></li>
                            <li><p>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any criminal offence or prevents investigation of any offence or is insulting any other nation;</p></li>
                            <li><p>is false, inaccurate or misleading;</p></li>
                            <li><p>directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force; or</p></li>
                            <li><p>creates liability for us or causes us to lose (in whole or in part) the services of our internet service provider or other suppliers.</p></li>
                        </ul>
                        <li><p>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. We reserve our right to prohibit any such activity.</p></li>
                        <li><p>You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, "password mining" or any other illegitimate means.</p></li>
                        <li><p>You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to Platform, or any other Buyer, including any account on the Platform not owned by you, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Platform.</p></li>
                        <li><p>You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name or domain name used by us including the name 'HungryTop', or otherwise engage in any conduct or action that might tarnish the image or reputation, of HungryTop or Merchant on platform or otherwise tarnish or dilute any HungryTop's trade or service marks, trade name and/or goodwill associated with such trade or service marks, as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or HungryTop's systems or networks, or any systems or networks connected to HungryTop.</p></li>
                        <li><p>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person's use of the Platform.</p></li>
                        <li><p>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the Platform or any service offered on or through the Platform. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.</p></li>
                        <li><p>You may not use the Platform or any content on the Platform for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity that infringes the rights of HungryTop and/or others.</p></li>
                        <li><p>You shall at all times ensure full compliance with the applicable provisions, as amended from time to time, of (a) the Information Technology Act, 2000 and the rules thereunder; (b) all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force); and (c) international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not limited to sales tax/VAT, income tax, octroi, service tax, central excise, custom duty, local levies) regarding your use of our service and your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</p></li>
                        <li><p>In order to allow us to use the information supplied by you, without violating your rights or any laws, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in your Information, in any media now known or not currently known, with respect to your Information. We will only use your information in accordance with these Terms of Use and Privacy Policy applicable to use of the Platform.</p></li>
                        <li><p>From time to time, you shall be responsible for providing information relating to the products or services proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or overemphasize the attributes of such products or services so as to mislead other users in any manner.</p></li>
                        <li><p>You shall not engage in advertising to, or solicitation of, other users of the Platform to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other users via the Platform. It shall be a violation of these Terms of Use to use any information obtained from the Platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than us without our prior explicit consent. In order to protect our users from such advertising or solicitation, we reserve the right to restrict the number of messages or emails which a user may send to other users in any 24-hour period which we deem appropriate in its sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</p></li>
                        <li><p>We reserve the right, but has no obligation, to monitor the materials posted on the Platform. HungryTop shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect HungryTop views. In no event shall HungryTop assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.</p></li>
                        <li><p>Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.</p></li>
                        <li><p>It is possible that other users (including unauthorized users or 'hackers') may post or transmit offensive or obscene materials on the Platform and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Platform, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Platform You acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the Platform. Please carefully select the type of information that you publicly disclose or share with others on the Platform.</p></li>
                        <li><p>HungryTop shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services), hacking, pen testing attempts without our prior consent or a mutual legal agreement.</p></li>
                    </ul>
                    <ul>
                        <li><b>Account Registration </b></li>
                        <ul>
                            <li><p>You may access the Platform by registering to create an account (" <b>HungryTop Account</b>") and become a member ("<b> Membership</b>"); or (c) you can also register to join by logging into your account with certain third party social networking sites ("<b>SNS</b>") (including, but not limited to, Facebook); each such account, a " <b>Third Party Account</b>", via our Platform, as described below. The Membership is limited for the purpose and are subject to the terms, and strictly not transferable. As part of the functionality of the Platform services, you may link your HungryTop Account with Third Party Accounts, by either:</p></li>
                            <ul>
                                <li><p>providing your Third Party Account login information to us through the Platform; or</p></li>
                                <li><p>allowing us to access your Third Party Account, as is permitted under the applicable terms and conditions that govern your use of each Third Party Account.</p></li>
                            </ul>
                            <li><p>You represent that you are entitled to disclose your Third Party Account login information to us and/or grant us access to your Third Party Account (including, but not limited to, for use for the purposes described herein), without breach by you of any of the terms and conditions that govern your use of the applicable Third Party Account and without obligating us to pay any fees or making us subject to any usage limitations imposed by such third party service providers.</p></li>
                            <li><p>By granting us access to any Third Party Accounts, you understand that we will access, make available and store (if applicable) any content or information that you have provided to and stored in your Third Party Account ("<b> SNS Content</b>") so that it is available on and through the Platform via your HungryTop Account.</p></li>
                            <li><p>Unless otherwise specified in these Terms of Use, all SNS Content, if any, will be considered to be your content for all purposes of these Terms of Use.</p></li>
                            <li><p>Depending on the Third Party Accounts, you choose, and subject to the privacy settings that you have set in such Third Party Accounts, personally identifiable information that you post to your Third Party Accounts will be available on and through your HungryTop Account on the Platform.</p></li>
                            <li><p>Please note that if a Third Party Account or associated service becomes unavailable or our access to such Third Party Account is terminated by the third party service provider, then SNS Content will no longer be available on and through the Platform.</p></li>
                            <li><p>We will create your HungryTop Account for your use of the Platform services based upon the personal information you provide to us or that we obtain via SNS, as described above. You can only have one HungryTop Account and are not permitted to create multiple accounts. HungryTop reserves the right to suspend such multiple accounts without being liable for any compensation where you have created multiple accounts on the Platform.</p></li>
                            <li><p>You agree to provide accurate, current and complete information during the registration process and update such information to keep it accurate, current and complete.</p></li>
                            <li><p>We reserve the right to suspend or terminate your HungryTop Account and your access to the Services (i) if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete; (ii) if it is believed that your actions may cause legal liability for you, other users or us; and/or (iii) if you are found to be non-compliant with the Terms of Use or other HungryTop policies.</p></li>
                            <li><p>You are responsible for safeguarding your password. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your HungryTop Account, whether or not you have authorized such activities or actions. You will immediately notify us of any unauthorized use of your HungryTop Account.</p></li>
                            <li><p>Goods and services purchased from the Platform are intended for your personal use and you represent that the same are not for resale or you are not acting as an agent for other parties.</p></li>
                        </ul>
                        <li><b>Order Booking and Financial Terms</b></li>
                        <ul>
                            <li><p>The Platform allows the Buyers to place Orders and upon acceptance of such Orders by the Merchants, HungryTop will, subject to the terms and conditions set out herein, facilitates delivery of goods or services, or completion of Tasks through PDP.</p></li>
                            <li><p>HungryTop does not own, sell, resell on its own such products offered by the Merchants, and/or does not control the Merchants or the related services provided in connection thereof. Buyer understands that any Order that he/she places shall be subject to the terms and conditions set out in these Terms of Use including, but not limited to, product availability, delivery location serviceability, and acceptance of Orders by Merchants/PDPs.</p></li>
                            <li><p>As a general rule, all Orders placed on the Platform and Delivery Services are treated as confirmed.</p></li>
                            <li><p>However, upon Buyer’s successful completion of booking an Order, we may call the Buyer on the telephone or mobile number provided to confirm the details of such Order, price to be paid and the estimated delivery time. For this purpose, Buyer will be required to share certain information with us, including but not limited to Buyer’s (i) first and last name (ii) mobile number; and (iii) email address. It shall be Buyer’s sole responsibility to bring any incorrect details to our attention.</p></li>
                            <li><p>In addition to the foregoing, we may also contact you by phone and / or email to inform and confirm any change in the Order, due to availability or unavailability or change in Order or change in price of any item in the Order as informed by the Merchant. Please note that any change or confirmation of the Order shall be treated as final. It is clarified that HungryTop reserves the right to not to process Buyer's Order in the event Buyer or Merchant or PDP is unavailable on the phone or any other means of communication at the time when we call you for confirming the Order and such event the provisions of the Cancellation and Refund Policy shall be applicable.</p></li>
                            <li><p>All payments made against the Orders or Services on the Platform by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. The Platform will not facilitate transactions with respect to any other form of currency with respect to the Orders or Services made on Platform. You can pay by (i) credit card or debit card or net banking; (ii) any other RBI approved payment method at the time of booking an Order; or (iii) credit or debit card or cash at the time of delivery. You understand, accept and agree that the payment facility provided by HungryTop is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment on delivery, collection and remittance facility for the transactions on the Platform using the existing authorized banking infrastructure and credit card payment gateway networks. Further, by providing payment facility, HungryTop is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price.</p></li>
                            <li><p>Buyers acknowledge and agree that HungryTop acts as the Merchant's and PDP’s payment agent for the limited purpose of accepting payments from Buyers/Merchants on behalf of the Merchant or PDP, as the case may be. Upon your payment of amounts to us, which are due to the Merchant or PDP, your payment obligation to the Merchant or PDP for such amounts is completed, and we are responsible for remitting such amounts to the Merchant or PDP. You shall not, under any circumstances whatsoever, make any payment directly to the Merchant for Order bookings or to the PDP for delivery of the Order or completion of the Task made using the Platform.</p></li>
                            <li><p>Buyer agrees to pay for the total amount for the Order placed on the Platform. HungryTop will collect the total amount in accordance with these Terms of Use and the pricing terms set forth in the applicable listing of product or restaurant service for the particular Merchant, apart from the delivery fees for Delivery Services. Please note that we cannot control any amount that may be charged to Buyer by his/her bank related to our collection of the total amount, and we disclaim all liability in this regard.</p></li>
                            <li><p>In connection with Buyer’s Order, he/she will be asked to provide customary billing information such as name, billing address and credit card information either to us or our third party payment processor. Buyer agrees to pay us for the Order placed by you on the Platform, in accordance with these Terms, using the methods described under clause VIII (6) above. Buyer hereby authorizes the collection of such amounts by charging the credit card provided as part of requesting the booking, either directly by us or indirectly, via a third party online payment processor or by one of the payment methods described on the Platform. If Buyer is directed to our third-party payment processor, he/she may be subject to terms and conditions governing use of that third party's service and that third party's personal information collection practices. Please review such terms and conditions and privacy policy before using the Platform services. Once the Order is confirmed you will receive a confirmation email summarizing the confirmed booking.</p></li>
                            <li><p>The final tax bill will be issued by the Merchant and PDP (if registered for tax purposes) to the Buyer along with the Order and HungryTop is merely collecting the payment on behalf of such Merchant and PDP. All applicable taxes and levies, the rates thereof and the manner of applicability of such taxes on the bill are being charged and determined by the Merchant and PDP. HungryTop holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole responsibility for any legal issue arising on the taxes shall reside with the Merchant and the PDP.</p></li>
                            <li><p>The prices reflected on the Platform, including packaging or handling charges, are determined solely by the Merchant and are listed based on Merchant's information. Very rarely, prices may change at the time of placing Order due to Merchant changing the menu price without due intimation and such change of price are at the sole discretion of the Merchant attributing to various factors beyond control.</p>
                                <p>Disclaimer: Prices on any product(s) as reflected on the Platform may due to some technical issue, typographical error or product information supplied by Merchant be incorrectly reflected and in such an event Merchant may cancel Buyer’s Order(s).</p></li>
                            <li><p>The Merchant shall be solely responsible for any warranty/guarantee of the goods or services sold to the Buyers and in no event shall be the responsibility of HungryTop.</p></li>
                            <li><p>The transactions are bilateral between the Merchant and Buyer, and between Merchant/Buyer and PDP, therefore, HungryTop is not liable to charge or deposit any taxes applicable on such transactions. </p></li>
                        </ul>
                        <li><p><b>Cancellations and Refunds</b></p>
                            <p>Please refer to the Cancellation and Refund Policy for cancellation and refunds terms in relation to usage of the Platform for availing Services.  </p></li>
                    </ul>
                    <h4>Terms of service</h4>
                    <ul>
                        <li><p>The Buyer agrees and acknowledges that HungryTop shall not be responsible for:</p></li>
                        <ul>
                            <li><p>The services or goods provided by the Merchants including but not limited to serving of food Orders suiting your requirements and taste;</p></li>
                            <li><p>The Merchant's services or goods, or services provided by PDPs not being up to Buyer expectations or leading to any loss, harm or damage to him/her;</p></li>
                            <li><p>The availability or unavailability of certain items on the menu;</p></li>
                            <li><p>The Merchant serving the incorrect Orders; or</p></li>
                            <li><p>Product liability of goods provided by Merchants.</p></li>
                            <li><p>The details of the menu and price list available on the Platform with respect to restaurant services, goods or any other services are based on the information provided by the Merchants and HungryTop shall not be responsible for any change or cancellation or unavailability.</p></li>
                            <li><p>Buyers and Merchants agree and acknowledge that HungryTop is not responsible for any liability arising out of delivery services provided by PDP to them.</p></li>
                            <li><p>Buyers may not be able to avail Services if their delivery location is outside HungryTop’s current scope of Service. HungryTop will keep the Buyer informed of the same at the time of confirming his/her Order booking.</p></li>
                            <li><p>Buyer understands that delivery time quoted at the time of confirming the Order is an approximate estimate and may vary based on the information obtained from PDPs and Merchants. HungryTop will not be responsible for any delay in the delivery of an Order.</p></li>
                            <li><p>Buyer understands that there are certain Merchants who undertake delivery of their goods and services to the Buyer and the Merchant may charge the Buyer for such service. HungryTop exercises no control on such delivery services and same shall be under the control of Merchant alone and hence all or any disputes arising out of such delivery services shall be between Buyer and Merchant alone. HungryTop shall not be responsible for such delivery services and assumes no liability for disputes arising out of the same.</p></li>
                            <li><p>Buyer’s Order will be only delivered to the address designated by him/her at the time of placing the Order on the Platform. Buyer’s Order will be cancelled in the event of any change of the address as informed by the PDP and Buyer shall not be entitled to any refund for the same. Delivery of goods and services in the event of change of the delivery location shall be subject to acceptance by the PDP or sole discretion of HungryTop.</p></li>
                            <li><p>The Buyer shall undertake to provide adequate directions, information and authorisations to accept delivery. In the event of no delivery due to any act or omission attributable to Buyer, the goods or services shall be deemed to have been delivered to the Buyer and all risk and responsibility in relation thereto shall pass to the Buyer without being entitled to any refund.</p></li>
                            <li><p>The Buyer understands that HungryTop’s (including Merchant’s and PDP’s) liability ends once Order has been delivered to him/her, except where the product liability of the Merchant subsists. </p></li>
                            <li><p>Services provided:</p></li>
                            <ul>
                                <li><p>You agree and acknowledge that HungryTop shall be liable in the event you have failed to adhere to the Terms of Use.</p></li>
                                <li><p>Buyer shall be required to provide credit or debit card details to the approved payment gateways while making the payment on the Platform. In this regard, Buyer agrees to provide correct and accurate credit/ debit card details to the approved payment gateways for availing the Services. Buyer shall not use the credit/ debit card which is not lawfully owned by Buyer, i.e. in any transaction, Buyer must use his/her own credit/ debit card. The information provided by the Buyer will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation or court order. Buyer shall be solely responsible for the security and confidentiality of his/her credit/ debit card details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of your credit/ debit card.</p></li>
                            </ul>
                            <li><p>HungryTop does not offer any refunds against goods or services already purchased from a Merchant or PDP through the Platform unless an error that is directly attributable to HungryTop has occurred during the purchase of such product or services.</p></li>
                            <li><p>We constantly strive to provide you with accurate information on the Platform. However, in the event of an error, we may, in our sole discretion, contact you with further instructions.</p></li>
                            <li><p>If you use the Platform, you do the same at your own risk.</p></li>
                            <li><p>Buyer agrees that the Services shall be provided through the Platform only during the working hours of the relevant Merchants and PDPs.</p></li>
                        </ul>
                        <li><p><b>No Endorsement</b></p></li>
                        <ul>
                            <li><p>We do not endorse any Merchant. In addition, although these Terms of Use require you to provide accurate information, we do not attempt to confirm, and do not confirm if it is purported identity. We will not be responsible for any damage or harm resulting from your interactions with other Members.</p></li>
                            <li><p>By using the Services, you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other Members or other third parties will be limited to a claim against the particular Members or other third parties who caused you harm and you agree not to attempt to impose liability on, or seek any legal remedy from us with respect to such actions or omissions.</p></li>
                        </ul>
                        <li><p><b>General</b></p></li>
                        <ul>
                            <li><p>Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Platform. Only individuals who are 18 years of age or older may use the Platform and avail Services. If you are under 18 years of age and you wish to download, install, access or use the Platform, your parents or legal guardian must acknowledge and agree to the Terms of Use and Privacy Policy. Should your parents or legal guardian fail to agree or acknowledge the Terms of Use and HungryTop policies, you shall immediately discontinue its use. HungryTop reserves the right to terminate your Membership and / or deny access to the platform if it is brought to HungryTop's notice that you are under the age of 18 years.</p></li>
                            <li><p>If you choose to use the Platform, it shall be your responsibility to treat your user identification code, password and any other piece of information that we may provide, as part of our security procedures, as confidential and not disclose the same to any person or entity other than us. We shall at times and at our sole discretion reserve the right to disable any user identification code or password if you have failed to comply with any of the provisions of these Terms of Use.</p></li>
                            <li><p>As we are providing services in the select cities in India, we have complied with applicable laws of India in making the Platform and its content available to you. In the event the Platform is accessed from outside India or outside our delivery zones, it shall be entirely at your risk. We make no representation that the Platform and its contents are available or otherwise suitable for use outside select cities. If you choose to access or use the Platform from or in locations outside select cities, you do so on your own and shall be responsible for the consequences and ensuring compliance of applicable laws, regulations, byelaws, licenses, registrations, permits, authorisations, rules and guidelines.</p></li>
                            <li><p>You shall at all times be responsible for the use of the Services through your computer or mobile device and for bringing these Terms of Use and HungryTop policies to the attention of all such persons accessing the Platform on your computer or mobile device.</p></li>
                            <li><p>You understand and agree that the use of the Services does not include the provision of a computer or mobile device or other necessary equipment to access it. You also understand and acknowledge that the use of the Platform requires internet connectivity and telecommunication links. You shall bear the costs incurred to access and use the Platform and avail the Services, and we shall not, under any circumstances whatsoever, be responsible or liable for such costs.</p></li>
                            <li><p>You agree and grant permission to HungryTop to receive promotional SMS and e-mails from HungryTop or allied partners. In case you wish to opt out of receiving promotional SMS or email please send a mail to info@hungrytop.com.</p></li>
                            <li><p>By using the Platform you represent and warrant that:</p></li>
                            <ul>
                                <li><p>All registration information you submit is truthful, lawful and accurate and that you agree to maintain the accuracy of such information.</p></li>
                                <li><p>Your use of the Platform shall be solely for your personal use and you shall not authorize others to use your account, including your profile or email address and that you are solely responsible for all content published or displayed through your account, including any email messages, and your interactions with other users and you shall abide by all applicable local, state, national and foreign laws, treaties and regulations, including those related to data privacy, international communications and the transmission of technical or personal data.</p></li>
                                <li><p>You will not submit, post, upload, distribute, or otherwise make available or transmit any content that: (a) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or constitute an invasion of a right of privacy of another person; (b) is bigoted, hateful, or racially or otherwise offensive; (c) is violent, vulgar, obscene, pornographic or otherwise sexually explicit; (d) is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them.</p></li>
                                <li><p>All necessary licenses, consents, permissions and rights are owned by you and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by these Terms of Use and Privacy Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any content that you submit, post, upload, distribute or otherwise transmit or make available.</p></li>
                                <li><p>You will not (a) use any services provided by the Platform for commercial purposes of any kind, or (b) advertise or sell any products, services or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind. In the event you want to advertise your product or service contact contact@HungryTop.in.</p></li>
                                <li><p>You will not use the Platform in any way that is unlawful, or harms us or any other person or entity, as determined in our sole discretion.</p></li>
                                <li><p>You will not post, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform or any connected network, or otherwise interfere with any person or entity's use or enjoyment of the Platform.</p></li>
                                <li><p>You will not use another person's username, password or other account information, or another person's name, likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or affiliation with any person or entity.</p></li>
                                <li><p>You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming," "spamming," "flooding," "trolling," and "griefing" as those terms are commonly understood and used on the Internet.</p></li>
                                <li><p>You will not delete or modify any content of the Platform, including but not limited to, legal notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or have express permission to modify.</p></li>
                                <li><p>You will not post or contribute any information or data that may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our interest.</p></li>
                                <li><p>You shall not access the Platform without authority or use the Platform in a manner that damages, interferes or disrupts:</p></li>
                                <ul>
                                    <li><p>any part of the Platform or the Platform software; or</p></li>
                                    <li><p>any equipment or any network on which the Platform is stored or any equipment of any third party</p></li>
                                </ul>
                                <li><p>You release and fully indemnify HungryTop and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Platform and specifically waive any claims that you may have in this behalf under any applicable laws of India. Notwithstanding its reasonable efforts in that behalf, HungryTop cannot take responsibility or control the information provided by other Users which is made available on the Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Platform.</p></li>
                            </ul>
                        </ul>
                        <li><p><b>Access to the Platform, Accuracy and security</b></p></li>
                        <ul>
                            <li><p>We endeavour to make the Services available during Merchant or PDP working hours. However, we do not represent that access to the Platform will be uninterrupted, timely, error free, free of viruses or other harmful components or that such defects will be corrected.</p></li>
                            <li><p>We do not warrant that the Platform will be compatible with all hardware and software which you may use. We shall not be liable for damage to, or viruses or other code that may affect, any equipment (including but not limited to your mobile device), software, data or other property as a result of your download, installation, access to or use of the Platform or your obtaining any material from, or as a result of using, the Platform. We shall also not be liable for the actions of third parties.</p></li>
                            <li><p>We do not represent or warranty that the information available on the Platform will be correct, accurate or otherwise reliable.</p></li>
                            <li><p>We reserve the right to suspend or withdraw access to the Platform to you personally, or to all users temporarily or permanently at any time without notice. We may any time at our sole discretion reinstate suspended users. A suspended User may not register or attempt to register with us or use the Platform in any manner whatsoever until such time that such user is reinstated by us.</p></li>
                        </ul>
                        <li><b>Relationship with operators if the Platform is accessed on mobile devices</b></li>
                        <ul>
                            <li><p>In the event the Platform is accessed on a mobile device, it is not associated, affiliated, sponsored, endorsed or in any way linked to any platform operator, including, without limitation, Apple, Google, Android or RIM Blackberry (each being an "<b> Operator</b>").</p></li>
                            <li><p>Your download, installation, access to or use of the Platform is also bound by the terms and conditions of the Operator.</p></li>
                            <li><p>You and we acknowledge that these Terms of Use are concluded between you and HungryTop only, and not with an Operator, and we, not those Operators, are solely responsible for the Platform and the content thereof to the extent specified in these Terms of Use.</p></li>
                            <li><p>The license granted to you for the Platform is limited to a non-transferable license to use the Platform on a mobile device that you own or control and as permitted by these Terms of Use.</p></li>
                            <li><p>We are solely responsible for providing any maintenance and support services with respect to the Platform as required under applicable law. You and we acknowledge that an Operator has no obligation whatsoever to furnish any maintenance and support services with respect to the Platform.</p></li>
                            <li><p>You and we acknowledge that we, not the relevant Operator, are responsible for addressing any claims of you or any third party relating to the Platform or your possession and/or use of the Platform, including, but not limited to: (i) any claim that the Platform fails to conform to any applicable legal or regulatory requirement; and (ii) claims arising under consumer protection or similar legislation.</p></li>
                            <li><p>You and we acknowledge that, in the event of any third party claim that the Platform or your possession and use of the Platform infringes that third party's intellectual property rights, we, not the relevant Operator, will be solely responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim.</p></li>
                            <li><p>You must comply with any applicable third party terms of agreement when using the Platform (e.g. you must ensure that your use of the Platform is not in violation of your mobile device agreement or any wireless data service agreement).</p></li>
                            <li><p>You and we acknowledge and agree that the relevant Operator, and that Operator's subsidiaries, are third party beneficiaries of these Terms of Use, and that, upon your acceptance of these Terms of Use, that Operator will have the right (and will be deemed to have accepted the right) to enforce these Terms of Use against you as a third party beneficiary thereof.</p></li>
                            <li><p></p></li>
                        </ul>
                        <li><b>Disclaimers</b></li>
                        <ul>
                            <li><p>THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND FEATURES MAY NOT BE FULLY OPERATIONAL.</p></li>
                            <li><p>DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT IN EVERY CASE.</p></li>
                            <li><p>WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.</p></li>
                            <li><p>YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY REGARDING ANY THIRD PARTY'S SERVICES NOR WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING FROM OR IN CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO, ANY LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU HEREBY DISCLAIM AND WAIVE ANY RIGHTS AND CLAIMS YOU MAY HAVE AGAINST US WITH RESPECT TO THIRD PARTY'S / MERCHANT’S/ PDP’S SERVICES.</p></li>
                            <li><p>HUNGRYTOP DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF ANY APPLICABLE LAWS INCLUDING THE LAW APPLICABLE TO PRODUCTS AND SERVICES OFFERED BY THE MERCHANT OR PDP.</p></li>
                            <li><p>WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE INFORMATION CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE ON SUCH INFORMATION.</p></li>
                            <li><p>THE INFORMATION PROVIDED HEREUNDER IS PROVIDED "AS IS". WE AND / OR OUR EMPLOYEES MAKE NO WARRANTY OR REPRESENTATION REGARDING THE TIMELINESS, CONTENT, SEQUENCE, ACCURACY, EFFECTIVENESS OR COMPLETENESS OF ANY INFORMATION OR DATA FURNISHED HEREUNDER OR THAT THE INFORMATION OR DATA PROVIDED HEREUNDER MAY BE RELIED UPON. MULTIPLE RESPONSES MAY USUALLY BE MADE AVAILABLE FROM DIFFERENT SOURCES AND IT IS LEFT TO THE JUDGEMENT OF USERS BASED ON THEIR SPECIFIC CIRCUMSTANCES TO USE, ADAPT, MODIFY OR ALTER SUGGESTIONS OR USE THEM IN CONJUNCTION WITH ANY OTHER SOURCES THEY MAY HAVE, THEREBY ABSOLVING US AS WELL AS OUR CONSULTANTS, BUSINESS ASSOCIATES, AFFILIATES, BUSINESS PARTNERS AND EMPLOYEES FROM ANY KIND OF PROFESSIONAL LIABILITY.</p></li>
                            <li><p>WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSSES OR INJURY ARISING OUT OF OR RELATING TO THE INFORMATION PROVIDED ON THE PLATFORM. IN NO EVENT WILL WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN BY YOUR RELIANCE ON THE CONTENT CONTAINED HEREIN.</p></li>
                            <li><p>IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL DEATH, AND DAMAGES RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION), RESULTING FROM ANY SERVICES PROVIDED BY ANY THIRD PARTY OR MERCHANT ACCESSED THROUGH THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p></li>
                        </ul>
                        <li><b>Intellectual property</b></li>
                        <ul>
                            <li><p>We are either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual property, in the Platform, and in the material published on it including but not limited to user interface, layout format, Order placing process flow and any content thereof.</p></li>
                            <li><p>You recognize that HungryTop is the registered owner of the word mark ‘HungryTop’ and the logo  including but not limited to its variants (IPR) and shall not directly or indirectly, attack or assist another in attacking the validity of, or HungryTop’s or its affiliates proprietary rights in, the licensed marks or any registrations thereof, or file any applications for the registration of the licensed marks or any names or logos derived from or confusingly similar to the licensed marks, any variation thereof, or any translation or transliteration thereof in another language, in respect of any products/services and in any territory throughout the world. If you become aware or acquire knowledge of any infringement of IPR you shall report the same at <Link to="#">business@hungrytop.com</Link> with all relevant information.</p></li>
                            <li><p>You may print off one copy, and may download extracts, of any page(s) from the Platform for your personal reference and you may draw the attention of others within your organisation to material available on the Platform.</p></li>
                            <li><p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p></li>
                            <li><p>You must not use any part of the materials on the Platform for commercial purposes without obtaining a licence to do so from us or our licensors.</p></li>
                            <li><p>If you print off, copy or download any part of the Platform in breach of these Terms of Use, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p></li>
                        </ul>
                        <li><p><b>Treatment of information provided by you</b></p>
                            <p>We process information provided by you to us in accordance with our Privacy Policy.</p>
                        </li>
                        <li><b>Third Party Content</b></li>
                        <ul>
                            <li><p>We cannot and will not assure that other users are or will be complying with the foregoing rules or any other provisions of these Terms of Use, and, as between you and us, you hereby assume all risk of harm or injury resulting from any such lack of compliance.</p></li>
                            <li><p>You acknowledge that when you access a link that leaves the Platform, the site you will enter into is not controlled by us and different terms of use and Privacy Policy may apply. By assessing links to other sites, you acknowledge that we are not responsible for those sites. We reserve the right to disable links to and / or from third-party sites to the Platform, although we are under no obligation to do so.</p></li>
                        </ul>
                        <li>
                            <p><b>Severability</b></p>
                            <p>If any of these Terms of Use should be determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any state or country in which these Terms of Use are intended to be effective, then to the extent and within the jurisdiction where that term is illegal, invalid or unenforceable, it shall be severed and deleted and the remaining Terms of Use shall survive, remain in full force and effect and continue to be binding and enforceable.</p>
                        </li>
                        <li>
                            <p><b>Non-assignment</b></p>
                            <p>You shall not assign or transfer or purport to assign or transfer the contract between you and us to any other person.</p>
                        </li>
                        <li>
                            <p><b>Governing law and dispute resolution</b></p>
                            <p>These Terms of Use are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to this Platform, shall be subject to the jurisdiction of the courts at Kolkata, India.</p>
                        </li>
                        <li><b>IP Notice and Take Down Policy</b></li>
                        <ul>
                            <li><p>HungryTop has put in place IP Notice and Take Down Policy (" <b>Take Down Policy</b>") so that intellectual property owners could easily report listings that infringe their right to ensure that infringing products are removed from the site, as they erode Buyer and good Seller trust.</p></li>
                            <li><p>Only the intellectual property rights owner can report potentially infringing products or listings through Take Down Policy by way of Notice of infringement in the specified format. If you are not the intellectual property rights owner, you can still help by getting in touch with the rights owner and encouraging them to contact us.        </p></li>
                            <li><p>Note: HungryTop does not and cannot verify that Merchants have the right or ability to sell or distribute their listed products or services. However, HungryTop is committed to ensure that item listings do not infringe upon intellectual property rights of third parties once an authorized representative of the rights owner properly reports them to HungryTop.</p></li>
                        </ul>
                        <li>
                            <p><b>Contact Us</b></p>
                            <p>Please contact us at info@hungrytop.com for any questions or comments (including all inquiries unrelated to copyright infringement) regarding this Platform.</p>
                            <p>Grievance Officer/Nodal Officer</p>
                            <p>In accordance with (1) Information Technology Act, 2000 and rules made there under, and (2) Consumer Protection (E-Commerce) Rules 2020l the name and contact details of the Grievance Officer/Nodal Officer is provided below:</p>
                            <p>Mr. Amitava Roy Chowdhoury</p>
                            <p>Hungry Top Technologies Limited</p>
                            <p>Reg Office: BL-5, PH-II, Golf Green Urban Complex, </p>
                            <p>Kolkata- 7000095, West Bengal, India</p>
                            <p>Phone: +91 - 9830159116</p>
                            <p>Email: <u>grievance@hungrytop.in</u></p>
                            <p>Time: Monday - Friday (9:00 - 18:00)</p>
                        </li>
                        <li>
                            <p><b>HungryTop HAMMER Membership Program Specific Terms</b></p>
                            <p>Effective from 1st June 2021</p>
                            <p>Welcome to the terms and conditions ("Terms") for HungryTop Hammer ("HungryTop HAMMER") membership program and offered by HungryTop to its Users on the Platform. These Terms shall govern your use of our service offered under HungryTop HAMMER Membership Program. Please read these Terms carefully before you subscribe to the HungryTop HAMMER Program. These Terms are subject to modifications, to comply with a legal requirement. You have the following options under the HungryTop HAMMER membership program and you can choose from these options at your sole discretion basis the suitability.</p>
                            <p><b>HAMMER Plans</b></p>
                            <p>Details of the Plans under the Hammer Program:</p>
                            <table className="t-c-table">
                                <thead>
                                    <tr>
                                        <th>Plans</th>
                                        <th><p>Price 1 week<br />Total price</p></th>
                                        <th><p>Price 1 month<br />Total price</p></th>
                                        <th>Number of Eligible auctions per week^</th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <td>OUTBID</td>
                                        <td>10</td>
                                        <td>35</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>
                                        <td>QUEST </td>
                                        <td>30</td>
                                        <td>105</td>
                                        <td>20</td>
                                    </tr>
                                    <tr>
                                        <td>KNOCKOUT (Unlimited)</td>
                                        <td>100</td>
                                        <td>300</td>
                                        <td>No limit</td>
                                    </tr>
                                </thead>
                            </table>
                            <ul>
                                <li><p>^ Eligible auctions to mean participation in an auction process, that take place on the app.</p></li>
                                <li><p>Month to be treated as 4 weeks or 28 days.</p></li>
                            </ul>
                        </li>
                        <li>
                            <p><b>HungryTop HAMMER Membership Program Specific Terms</b></p>
                            <p>HungryTop shall charge and You agree to pay such membership fee, as may be determined by HungryTop from time to time. You can use Credit Card, Debit Card, Net Banking & other third-party payment options available to subscribe to the HungryTop HAMMER program.</p>
                            <p>You can access the detailed Terms & Conditions in the Help Section.</p>
                        </li>
                        <li>
                            <p><b>Membership & Activation:</b></p>
                            <p>HungryTop users can purchase HungryTop HAMMER membership through all available online payment options. The subscription shall be activated upon successful payment through the HungryTop Platform. In case HungryTop HAMMER membership is purchased along with an Order and if such Order is cancelled, then the membership shall also stand cancelled and the HungryTop HAMMER membership subscription amount will be refunded.</p>
                        </li>
                        <li>
                            <p><b>No Cancellation or Termination:</b></p>
                            <p>HungryTop HAMMER membership once purchased, cannot be cancelled or Terminated.</p>
                        </li>
                        <li>
                            <p><b>Modification / Cancellation of membership by HungryTop</b></p>
                            <p>HungryTop may cancel Your HungryTop HAMMER membership at its sole discretion without notice. If we do so, we will issue a prorated refund based on the number of full months remaining in your HAMMER membership. However, we will not give any refund for cancellation of HungryTop HAMMER membership related to conduct that we determine, in our discretion, violates these Terms or any applicable law, involves fraud or misuse of the HungryTop HAMMER Membership, or is harmful to our interests or another user. Our failure to insist upon or enforce your strict compliance with these Terms will not constitute a waiver of any of our rights.</p>
                        </li>
                        <li>
                            <p><b>Important notice:</b></p>
                            <p>These Terms are special terms and as such restricted to only Buyer Orders placed through HungryTop HAMMER and as such does not supersede the HungryTop Terms of Use, unless specifically called out here.</p>
                            <p>We reserve the right to modify these Terms at any time without notice posting changes on the Platform and you shall be responsible to update yourself of such changes, if any, by accessing the changes on the Platform. Your continued usage of the HungryTop HAMMER Program after any change is posted constitutes your acceptance of the amendments.</p>
                        </li>
                        <li>
                            <p><b>General Terms & Conditions:</b></p>
                        </li>
                        <ul>
                            <li><p>We reserve the right to accept or refuse membership in our sole discretion</p></li>
                            <li><p>We may send you email, and other communication related to HungryTop HAMMER and your HungryTop HAMMER Membership (regardless of any settings or preferences related to your HungryTop account).</p></li>
                            <li><p>You may not transfer or assign your HungryTop HAMMER Membership or any HungryTop HAMMER benefits, except as allowed in these Terms.</p></li>
                            <li><p>From time to time, We may choose in its sole discretion to add or remove HungryTop HAMMER Membership benefits.</p></li>
                            <li><p>The delivery services shall be, and always be, provided by the PDP and HungryTop does not guarantee the provision of these services.</p></li>
                            <li><p>These benefits may not be applicable to those Merchants who undertake delivery of their restaurant services through their own delivery fleet.</p></li>
                            <li><p>HungryTop reserves the right to offer this HungryTop HAMMER membership in select cities, at its sole discretion.</p></li>
                            <li><p>HungryTop HAMMER Membership feature is available only on the App.</p></li>
                            <li><p>Discounts through coupon discounts on HungryTop, may not apply on HAMMER Subscriptions.</p></li>
                        </ul>
                        <li>
                            <p><b>Limitation of Liability</b></p>
                            <p>IN ADDITION TO OTHER LIMITATIONS AND EXCLUSIONS IN HUNGRYTOP'S CONDITIONS OF USE AND SALE, IN NO EVENT WILL WE OR OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, ARISING OUT OF OR RELATED TO HUNGRYTOP. OUR TOTAL LIABILITY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, WILL NOT EXCEED THE LAST MEMBERSHIP FEE YOU PAID. THESE EXCLUSIONS AND LIMITATIONS OF LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW AND WILL SURVIVE CANCELLATION OR TERMINATION OF YOUR HUNGRYTOP HAMMER MEMBERSHIP.</p>
                            <p>All disputes related to this HungryTop HAMMER Membership will be subject to the exclusive jurisdiction of courts of Kolkata only.</p>
                            <p>These Terms and Conditions are co-extensive and concurrent with HungryTop's other Terms and Conditions and the Privacy Policy listed on this Platform. As such, all other Terms and Conditions listed hereinbefore shall also be applicable to this HungryTop HAMMER Membership and the same need not be expressly repeated herein.</p>
                        </li>
                        <li><b>Notice and Take Down Policy Template and Procedures</b></li>
                        <ul>
                            <li><p>Reporting Instances of Intellectual Property Violations: To file a notice of infringement with HungryTop, you must provide a written communication (by email or regular mail) that sets out the information specified in the list below. Please note that you will be liable for damages (including costs and lawyer's fees) if you materially misrepresent that material is infringing your intellectual property right(s). Accordingly, if you are not sure if you are the proprietor of the intellectual property right(s), we suggest that you first contact a lawyer.</p></li>
                            <li><p>To expedite our ability to process your request, please use the following format:</p></li>
                            <ul>
                                <li><p>Identify with sufficient detail the protected work that you believe has been infringed. This includes identification of the web page or specific posts, as opposed to entire sites. Posts must be referenced by either the dates in which they appear or by the permalink of the post. Include the URL to the concerned material infringing your intellectual property right(s) [URL of a website or URL to a post, with title, date, name of the emitter], or link to initial post with sufficient data to find it;</p></li>
                                <li><p>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit HungryTop to locate the material;</p></li>
                                <li><p>Information reasonably sufficient to permit HungryTop to contact the complaining party, such as an address, telephone number, and, if available, an email address at which the complaining party may be contacted;</p></li>
                                <li><p>The following mandatory statement: "I have a good faith belief that use of the material in the manner complained of is not authorized by the intellectual property right(s) owner, its agent, or the law";</p></li>
                                <li><p>The following mandatory statement: "I swear, under penalty of perjury, that the information in the notification is accurate, and that I am the intellectual property right(s) owner or authorized to act on behalf of the owner of an exclusive intellectual property right(s) that is allegedly infringed";</p></li>
                                <li><p>Sign the document with either your physical or electronic signature; and</p></li>
                                <li><p>Send the written communication to: Email to grievance@hungrytop.in</p></li>
                            </ul>
                            <li><p>Format of notice of infringement:</p>
                                <p>To,<br />
                            Hungry Top Technologies Limited,<br />
                            BL-5, PH-II, Golf Green Urban Complex, <br />
                            Kolkata- 7000095, West Bengal, India<br />
                            I, ____________________________ of _________________________ do solemnly and sincerely declare as follows:</p>
                                <ol>
                                    <li>
                                        <p>I am the owner of certain intellectual property rights, said owner being named __________________ (" IP Owner").</p>
                                    </li>
                                    <li>
                                        <p>I have a good faith belief that use of the material in the manner complained of is not authorized by the intellectual property right(s) owner, its agent, or the law therefore infringe the IP Owner's rights. Please expeditiously remove or disable access to the material claimed to be infringing.</p>
                                    </li>
                                    <li>
                                        <p>I may be contacted at:<br />
                                        Name________________________________________________<br />
                                        Designation/Title & Company_________________________________________________________<br />
                                        Postal Address (with Pin code)________________________________________________________<br />
                                        Email Address (correspondence)__________________________________________<br />
                                        Telephone/Fax_____________________________________________________<br />
                                        I swear, under penalty of perjury, that the information in the notification is accurate, and that I am the intellectual property right(s) owner or am authorized to act on behalf of the owner of an exclusive intellectual property right(s) that is allegedly infringed and I make this declaration conscientiously believing it to be true and correct.<br />
                                        Declared by ___________________ on ___________________________________ at ________ Truthfully,<br />
                                        Signature ________________________
                                        </p>
                                        {/* <p>
                                            (Important Note: (a) HungryTop shall be unable to process requests which do not specify exact product IDs or URLs. Please do not provide links to browse pages or links of search queries as these pages are dynamic and their contents change with time. (b) HungryTop shall not consider an incomplete request. (c) HungryTop's response to such request will include removing or disabling access to material claimed to be the subject of infringing activity. For a detailed term of use of our Platform, please visit <Link to="https://www.hungrytop.com/terms-and-conditions">https://www.HungryTop.com/terms-and-conditions</Link>).
                                        </p> */}
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </ul>
                </div>
            </div>
        );
    }
}

export default TermConditions;