import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from "react-parallax";
// const image1 =
//     "https://images.unsplash.com/photo-1555243896-c709bfa0b564?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGZhc3QlMjBmb29kJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=60 100w";
const image1 = "../assets/images/food8.jpg";

class FromTable extends Component {
    render() {
        return (
            <div>
                <Parallax bgImage={image1} strength={200}>
                    <div style={{height: 900}}>
                        <section className="food-ad text-center">
                            <div className="bg-white from-table-container">
                                <h1 className="from-table-header">Farm to Table</h1>
                                <h3>A Sneak Peak of the <br />New Spring Menu</h3>
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <div>
                                        <div className="underline-black m-2"></div>
                                        <div className="underline-black m-2"></div>
                                    </div>
                                </div>
                                <div className="from-para">
                                    <p>Peas with Mint and Rose Petals</p>
                                    <p>Grilled Corn with Herb Butter</p>
                                    <p>Falafel-Spiced Tomatoes on Whole Wheat Flatbread</p>
                                    <p>Primavera Risotto</p>
                                    <p>Farmer’s Market Quinoa Salad</p>
                                    <p>Sparkling Wine with Ginger Ale and Cherries</p>
                                </div>
                                <button className="from-table-btn m-1"><Link to="#">Go to menus</Link></button>
                            </div>
                        </section>
                    </div>
                </Parallax>

            </div>
        );
    }
}

export default FromTable;