import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import TemporaryDrawer from './sidebar';

class Navber extends Component {
    render() {
        return (
            <div className="navber">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <img className="nav-logo" src="../assets/images/hungry-logo.svg" alt="svg" />
                        </div>
                        <div>
                            <div>
                                <ul className="m-0">
                                    <li><NavLink activeClassName='is-active' to="/" exact={true}>Home</NavLink></li>
                                    <li><NavLink activeClassName='is-active' to="/team">Team</NavLink></li>
                                    <li><NavLink activeClassName='is-active' to="/auction">Food Auction</NavLink></li>
                                    <li><NavLink activeClassName='is-active' to="/auctioneer">Auctioneer</NavLink></li>
                                    {/* <li><NavLink to="#">Menus</NavLink></li> */}
                                    <li><a href="/#service">Contact</a></li>
                                    <li><NavLink activeClassName='is-active' to="/bulletin">Bulletin</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="nav-sidebar">
                            <TemporaryDrawer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navber;