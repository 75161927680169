import React, { Component } from 'react';
import { Parallax } from 'react-parallax';
import Footer from './component/footer';
import Navber from './component/navber';

const img1 = "../assets/images/food11.jpg";
class auctioneer extends Component {
    render() {
        return (
            <div>
                <Navber />
                <Parallax bgImage={img1}>
                    <div style={{ height: 500 }}></div>
                </Parallax>
                <div className="container text-center">
                    <h2 className="head-content my-4">Our Auctioneer!</h2>
                    <Parallax>
                        <div style={{ height: 500 }}>
                            <img className="img-fluid" src="../assets/images/resturent.png" alt="" />
                        </div>
                    </Parallax>
                </div>
                <hr />
                <Footer />
            </div>
        );
    }
}

export default auctioneer;